<template>
    <div class="container">

        <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
                <!-- Nested Row within Card Body -->
                <div class="row">
                    <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
                    <div class="col-lg-7">
                        <div class="p-5">
                            <div class="text-center">
                                <h1 class="h4 text-gray-900 mb-4">Criar minha conta</h1>
                            </div>
                            <form class="user">
                                <div class="form-group row">
                                    <div class="col-sm-12 mb-3 mb-sm-0">
                                        <input type="text" class="form-control form-control-user" id="name"
                                            placeholder="Nome" v-model="name">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control form-control-user" id="mail"
                                        placeholder="E-mail" v-model="email">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control form-control-user" id="repeatMail"
                                        placeholder="Repetir E-mail" v-model="repeatEmail">
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6 mb-3 mb-sm-0">
                                        <input type="password" class="form-control form-control-user"
                                            id="password" placeholder="Senha" v-model="password">
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="password" class="form-control form-control-user"
                                            id="repeatPassword" placeholder="Repetir Senha" v-model="repeatPassword">
                                    </div>
                                </div>
                                <div v-on:click="register" class="btn btn-primary btn-user btn-block">
                                    Registrar
                                </div>
                            </form>
                            <hr>
                            <div class="text-center">
                                <router-link to="/forgot-password" class="small">
                                    Esqueceu a senha?
                                </router-link>
                            </div>
                            <div class="text-center">
                                <router-link to="/login" class="small">
                                    Já tem uma conta? Acesse!
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import firebase from 'firebase';
export default {
  name: 'Register',
  data() {
      return {
          name: '',
          email: '',
          repeatEmail: '',
          password: '',
          repeatPassword: ''
      };
  },
  methods: {
      register() {
          if (!this.checkForm()) {
              return
          }
          firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
          .then((credential) => {
              credential.user.updateProfile({
                  displayName: this.name
              })
              this.clearForm()
              this.$toast.open('Usuário registrado com sucesso')
          }).catch(error => {
              this.$toast.open({
                  type: 'error',
                  message: error.message
              })
          })
      },
      checkForm() {
            if (this.name.length < 3) {
                this.$toast.open({type: 'error', message: 'Favor informar um nome válido'})
                return false
            }

            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.email)) {
                this.$toast.open({type: 'error', message: 'Favor informar um e-mail válido'})
                return false
            }

            if (this.email != this.repeatEmail) {
                this.$toast.open({type: 'error', message: 'Os e-mails são diferentes'})
                return false
            }

            if (this.password.length < 6) {
                this.$toast.open({type: 'error', message: 'A senha deve conter no mínimo 6 caracteres'})
                return false
            }

            if (this.password != this.repeatPassword) {
                this.$toast.open({type: 'error', message: 'As senhas são diferentes'})
                return false
            }

            return true
      },
      clearForm() {
          this.name = ''
          this.email = ''
          this.repeatEmail = ''
          this.password = ''
          this.repeatPassword = ''
      }
  }
}
</script>