<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-10 col-lg-12 col-md-9">
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4">Seja bem-vindo!</h1>
                                    </div>
                                    <form class="user">
                                        <div class="form-group">
                                            <input type="email" class="form-control form-control-user"
                                                id="email" aria-describedby="emailHelp"
                                                placeholder="E-mail" v-model="email">
                                        </div>
                                        <div class="form-group">
                                            <input type="password" class="form-control form-control-user"
                                                id="password" placeholder="Senha" v-model="password">
                                        </div>
                                        <!--
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox small">
                                                <input type="checkbox" class="custom-control-input" id="customCheck">
                                                <label class="custom-control-label" for="customCheck">Remember
                                                    Me</label>
                                            </div>
                                        </div>
                                        -->
                                        <div v-on:click="login" class="btn btn-primary btn-user btn-block">
                                            Entrar
                                        </div>
                                        <!--
                                        <hr>
                                        <router-link to="/" class="btn btn-google btn-user btn-block">
                                            <i class="fab fa-google fa-fw"></i> Login with Google
                                        </router-link>
                                        <router-link to="/" class="btn btn-facebook btn-user btn-block">
                                            <i class="fab fa-facebook-f fa-fw"></i> Login with Facebook
                                        </router-link>
                                        -->
                                    </form>
                                    <hr>
                                    <div class="text-center">
                                        <router-link to="/register" class="small">
                                            Criar uma conta!
                                        </router-link>
                                    </div>
                                    <div class="text-center">
                                        <router-link to="/forgot-password" class="small">
                                            Esqueceu a senha?
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase';
export default {
  name: 'Login',
  data() {
      return {
          email: '',
          password: ''
      }
  },
  methods: {
      login() {
          if (!this.checkForm()) {
              return
          }

          firebase.auth().signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
              this.$router.push('/')
          }).catch(error => {
                this.$toast.open({type: 'error', message: error.message})
          })
      },
      checkForm() {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(this.email)) {
                this.$toast.open({type: 'error', message: 'Favor informar um e-mail válido'})
                return false
            }

            if (this.password.length < 6) {
                this.$toast.open({type: 'error', message: 'A senha deve conter no mínimo 6 caracteres'})
                return false
            }
            return true
      }
  }
}
</script>