<template>
    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-password-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-2">Esqueceu a senha?</h1>
                                        <p class="mb-4">Nós entendemos, isso acontece! Basta inserir seu endereço de e-mail abaixo
                                            e enviaremos um link para redefinir sua senha!</p>
                                    </div>
                                    <form class="user">
                                        <div class="form-group">
                                            <input type="email" class="form-control form-control-user"
                                                id="email" aria-describedby="emailHelp" v-model="email" 
                                                placeholder="E-mail">
                                        </div>

                                        <div @click="sendPasswordResetEmail" class="btn btn-primary btn-user btn-block">
                                            Recuperar senha
                                        </div>
                                    </form>
                                    <hr>
                                    <div class="text-center">
                                        <router-link to="/register" class="small">
                                            Criar uma conta!
                                        </router-link>
                                    </div>
                                    <div class="text-center">
                                        <router-link to="/login" class="small">
                                            Já tem uma conta? Acesse!
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import firebase from 'firebase'
export default {
  name: 'ForgotPassword',
  data() {
      return {email: ''}
  },
  methods: {
    clearForm() {
        this.email = ''
    },
    sendPasswordResetEmail() {
        if(!this.checkForm()) {
            return
        }
        firebase.auth().sendPasswordResetEmail(this.email).then(() => {
            this.$toast.open("Email enviando com sucesso!")
        }).catch(error => {
            this.$toast.open({type: 'error', message: error.message})
        }).finally(() => {
            console.log(this.email)
            this.clearForm()
        })
    },
    checkForm() {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.email)) {
            this.$toast.open({type: 'error', message: 'Favor informar um e-mail válido'})
            return false
        }
        return true
    }
  }
}
</script>