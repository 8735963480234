<template>
    <!-- Begin Page Content -->
    <div class="container-fluid">

        <!-- Page Heading -->
        <h1 class="h3 mb-4 text-gray-800">Usuários</h1>
        
        <!-- DataTales Example -->
        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Usuários do ERP na base de dados</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Marca</th>
                                <th>Tipo</th>
                                <th>Dt. Alteração</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Marca</th>
                                <th>Tipo</th>
                                <th>Dt. Alteração</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</template>

<script>
import $ from 'jquery'
import 'datatables.net-bs5'
import Item from '../../repositories/ItemRepository'

export default {
  name: 'ItemList',
  data() {
    return {
      jsonData: '',
      columns: []
    }
  },
  methods: {
    initColumns() {
      Object.getOwnPropertyNames(this.jsonData[0]).forEach((e) => {
        this.columns.push({data: e})
      })
      this.columns.pop()
    },
    initDataTable() {
      $('#dataTable').DataTable({
        "data": this.jsonData,
        columns: this.columns,
        language: {
          info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
          search: "Procurar",
          lengthMenu: "Exibir _MENU_ resultados por página",
          paginate: {
            first:      "Início",
            previous:   "Anterior",
            next:       "Próximo",
            last:       "Fim"
          }
        }
      })
    }
  },
  mounted() {
    Item.getAll().then((results) => {
      this.jsonData = results.data.data.tropicalpneus_item
      this.initColumns()
      this.initDataTable()
    })
  }
}
</script>