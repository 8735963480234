import axios from "axios"

const baseUrl = 'https://choice-egret-15.hasura.app/v1/graphql'
const token = 'wYtZEzgd3xx7oYsj0iEQrQSJOoY75s7MNeN609vq0iYq4YtW6Bo3gl55gyDbJ26A'
const headers = {
                    'Content-Type': 'application/json',
                    'x-hasura-admin-secret': token
                }

export default {
    getAll() {
        return axios({
            url: baseUrl,
            method: 'post',
            headers: headers,
            data: {
              query: `query {
                        tropicalpneus_client{
                          erp_id,
                          cpf_cnpj,
                          name,
                          email,
                          phone_number,
                          last_change
                        }
                      }`
            },
          })
    }
}