<template>
    <!-- Begin Page Content -->
    <div class="container-fluid">

        <!-- Page Heading -->
        <h1 class="h3 mb-4 text-gray-800">Minha Conta</h1>

        <div class="row">
            <div class="col-xl-4">
                <!-- Profile picture card-->
                <div class="card mb-4 mb-xl-0">
                    <div class="card-header">Profile Picture</div>
                    <div class="card-body text-center">
                        <!-- Profile picture image-->
                        <img class="img-account-profile rounded-circle mb-2" v-bind:src="photoURL" alt="">
                        <!-- Profile picture help block-->
                        <div class="small font-italic text-muted mb-4">JPG, PNG ou SVG. Máx. 5 MB</div>
                        <!-- Profile picture upload button-->
                        <input v-on:change="uploadPhoto" hidden id="photoUpload" ref="photoUpload" type="file" accept=".png, .jpg, .svg">
                        <button @click="choosePhoto()" class="btn btn-primary" type="button">Enviar imagem</button>
                    </div>
                </div>
            </div>
            <div class="col-xl-8">
                <!-- Account details card-->
                <div class="card mb-4">
                    <div class="card-header">Detalhes da Conta</div>
                    <div class="card-body">
                        <form>
                            <!-- Form Group (username)-->
                            <div class="mb-3">
                                <label class="small mb-1" for="inputUsername">Nome</label>
                                <input class="form-control" id="inputUsername" type="text" placeholder="Informe seu nome" v-model="name">
                            </div>
                            <div class="row gx-3 mb-3">
                                <!-- Form Group (organization name)-->
                                <div class="col-md-6">
                                    <label class="small mb-1" for="inputOrgName">Empresa</label>
                                    <input class="form-control" id="inputOrgName" type="text" placeholder="Nome da empresa" v-model="company">
                                </div>
                                <!-- Form Group (location)-->
                                <div class="col-md-6">
                                    <label class="small mb-1" for="inputLocation">Cidade - Estado</label>
                                    <input class="form-control" id="inputLocation" type="text" placeholder="Sua cidade - estado" v-model="location">
                                </div>
                            </div>
                            <!-- Form Group (email address)-->
                            <div class="mb-3">
                                <label class="small mb-1" for="inputEmailAddress">Email</label>
                                <input class="form-control" id="inputEmailAddress" type="email" placeholder="Seu endereço de email" v-model="email">
                            </div>
                            <!-- Form Row-->
                            <div class="row gx-3 mb-3">
                                <!-- Form Group (phone number)-->
                                <div class="col-md-6">
                                    <label class="small mb-1" for="inputPhone">Telefone</label>
                                    <input class="form-control" id="inputPhone" type="tel" placeholder="Seu número de telefone" v-model="phone">
                                </div>
                                <!-- Form Group (birthday)-->
                                <div class="col-md-6">
                                    <label class="small mb-1" for="inputBirthday">Data de nascimento</label>
                                    <input class="form-control" id="inputBirthday" type="text" name="birthday" placeholder="Sua data de nascimento" v-model="birthday">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- /.container-fluid -->
</template>

<script>

import firebase from 'firebase'
export default {
  name: 'Profile',
  data() {
      return {
        name: '',
        company: '',
        location: '',
        email: '',
        phone: '',
        birthday: '',
        photoURL: '',
      }
  },
  mounted() {
      var user = firebase.auth().currentUser
      this.name = user.displayName
      this.email = user.email
      this.phone = user.phoneNumber
      this.photoURL = user.photoURL ? user.photoURL : '/img/undraw_profile.svg'
  },
  methods: {
      choosePhoto() {
          document.getElementById("photoUpload").click()
      },
      uploadPhoto() {
            const file = this.$refs.photoUpload.files[0]

            if (!file) {
                this.$toast.open({type: 'error', message: 'Nenhum arquivo selecionado'})
                return
            }

            if (file.size > 1024 * 1024 * 5) {
                this.$toast.open({type: 'error', message: 'Arquivo maior que 5MB'})
                return
            }

            var user = firebase.auth().currentUser
            firebase.storage().ref().child(user.email).put(file).then((snapshot) => {
                snapshot.ref.getDownloadURL().then((downloadUrl) => {
                    user.updateProfile({
                        photoURL: downloadUrl
                    }).then(() => {
                        this.photoURL = downloadUrl
                        this.$toast.open('Foto atualizada!')
                    }).catch(error => {
                        this.$toast.open({type: 'error', message: error.message})
                    })
                })
            }).catch(error => {
                this.$toast.open({type: 'error', message: error.message})
          }).catch(error => {
                this.$toast.open({type: 'error', message: error.message})
          })
      }
  }
}
</script>