<template>
    <!-- Sidebar -->
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" :class="toggle" id="accordionSidebar">

        <!-- Sidebar - Brand -->
        <p></p>
        <div class="sidebar-card">
            <img src="img/movere-logo.png" alt="incitte-logo">
        </div>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Dashboard -->
        <li class="nav-item active">
            <router-link class="nav-link" to="/">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span></router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider">

        <!-- Heading -->
        <div class="sidebar-heading">
            Admin
        </div>

        <!-- Nav Item - Pages Collapse Menu -->
        <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#records"
                aria-expanded="true" aria-controls="collapseTwo">
                <i class="fas fa-fw fa-exchange-alt"></i>
                <span>Integração</span>
            </a>
            <div id="records" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <router-link to="/integration/sellerList" class="collapse-item">
                        <i class="fas fa-fw fa-user-tie"></i>
                        Vendedores
                    </router-link>
                    <router-link to="/integration/clientList" class="collapse-item">
                        <i class="fas fa-fw fa-user-tag"></i>
                        Clientes
                    </router-link>
                    <router-link to="/integration/itemList" class="collapse-item">
                        <i class="fas fa-fw fa-box"></i>
                        Itens
                    </router-link>
                </div>
            </div>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block">

        <!-- Sidebar Toggler (Sidebar) -->
        <div class="text-center d-none d-md-inline">
            <button @click="toggleSidebar" class="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
    </ul>
    <!-- End of Sidebar -->
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
        toggle: ''
    }
  },
  methods: {
      toggleSidebar() {
          this.toggle = this.toggle == '' ? 'toggled' : ''
      }
  }
}
</script>

<style>
img {
  width: 100%;
  height: auto;
}
</style>