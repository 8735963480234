<template>
    <!-- Footer -->
    <footer class="sticky-footer bg-white">
        <div class="container my-auto">
            <div class="copyright text-center my-auto">
                <a href="https://incitte.com.br" target="_blank">
                    <span>Incitte Consulting Company &copy;</span>
                </a>
            </div>
        </div>
    </footer>
    <!-- End of Footer -->
</template>

<script>
export default {
  name: 'Footer',
}
</script>