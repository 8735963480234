<template>
    <!-- Begin Page Content -->
    <div class="container-fluid">
        <!-- Page Heading -->
        <h1 class="h3 mb-4 text-gray-800">Settings</h1>
        <div class="col-lg-6">
          <div class="card mb-4">
              <div class="card-header">Alterar Senha</div>
              <div class="card-body">
                  <form>
                      <!-- Form Group (current password)-->
                      <div class="mb-3">
                          <label class="small mb-1" for="currentPassword">Senha Atual</label>
                          <input class="form-control" id="currentPassword" type="password" placeholder="Senha Atual" v-model="oldPassword">
                      </div>
                      <!-- Form Group (new password)-->
                      <div class="mb-3">
                          <label class="small mb-1" for="newPassword">Nova Senha</label>
                          <input class="form-control" id="newPassword" type="password" placeholder="Nova Senha" v-model="newPassword">
                      </div>
                      <!-- Form Group (confirm password)-->
                      <div class="mb-3">
                          <label class="small mb-1" for="confirmPassword">Repetir Nova Senha</label>
                          <input class="form-control" id="confirmPassword" type="password" placeholder="Repetir Nova Senha" v-model="repeatedNewPassword">
                      </div>
                      <button @click="changePasswword" class="btn btn-primary" type="button">Alterar</button>
                  </form>
              </div>
          </div>
        </div>
        
    </div>
    <!-- /.container-fluid -->
</template>

<script>
import firebase from 'firebase'
export default {
  name: 'Settings',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      repeatedNewPassword: ''
    }
  },
  methods: {
    clearForm() {
      this.oldPassword = ''
      this.newPassword = ''
      this.repeatedNewPassword = ''
    },
    changePasswword() {
      if (this.newPassword != this.repeatedNewPassword) {
        this.$toast.open({type: 'error', message: 'As senhas não são iguais'})
        return
      }

      if (this.newPassword == this.oldPassword) {
        this.$toast.open({type: 'error', message: 'A nova senha deve ser diferente da antiga'})
        return
      }

      const credential = firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        this.oldPassword
      )
      firebase.auth().currentUser.reauthenticateWithCredential(credential).then(() => {
        firebase.auth().currentUser.updatePassword(this.newPassword).then(() => {
          this.$toast.open("Senha alterada com sucesso!")
        })
      }).catch(error => {
        const message = error.code == 'auth/wrong-password' ? 'Senha incorreta' : error.message
        this.$toast.open({type: 'error', message: message})
      }).finally(() => {
        this.clearForm()
      })
    }
  }
}
</script>