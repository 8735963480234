import Vue from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

var firebaseConfig = {
  apiKey: "AIzaSyBGlCoXAUU8PHA9Dh-69IlqN1-m4erq3E4",
  authDomain: "incitte-sales.firebaseapp.com",
  projectId: "incitte-sales",
  storageBucket: "incitte-sales.appspot.com",
  messagingSenderId: "328157444325",
  appId: "1:328157444325:web:6f11a16c635d2455a9bc0c",
  measurementId: "G-KB1DKLSZ6E"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();



Vue.config.productionTip = false
Vue.use(VueToast);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
