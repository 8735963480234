<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  @import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
  
  body {
      background-image: linear-gradient(180deg,#00D2DB 10%,#1C3868 100%);
      background-size: cover;
  }
</style>